import React from "react";
import { Container, Row } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { Link } from "gatsby";
import Objet from "./objet";
import Carousel from "react-bootstrap/Carousel";

const objets = ({ articles }) => {
    const objets = articles;

    return (
        <div>
            <Container fluid className="mx-0 px-0 ">
                {/* <div className="text-right pr-3 my-0 d-none d-sm-block ">
                    <Link to="/" style={{ color: "black" }}>
                        <FontAwesomeIcon icon={faTimes} />
                    </Link>
                </div> */}

                <Row className="d-flex justify-content-start pt-2 pt-sm-0 ">
                    {objets.map((data, i) => {
                        return (
                            <Objet
                                objet={data}
                                key={i}
                            />
                        )
                    })}
                </Row>

            </Container>
        </div>
    );
};

export default objets;
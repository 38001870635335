import React from "react";
import { StaticQuery, graphql } from "gatsby";
import "../fonts/fonts.css";
import Objets from "./objets";

const Shop = () => {
    return (
        <>
            <StaticQuery
                query={graphql`
          query {
            allWcProducts(
              filter: {
                categories: { elemMatch: { name: { eq: "objet" } } }
              }
            ) {
              edges {
                node {
                  categories {
                    name
                  }
                  acf {
                    si_objet_ttechnique_de_production
                    auteur
                }
                  description
                  name
                  price
                  slug
                  wordpress_id
                  attributes {
                    name
                    options
                  }
                  images {
                    src
                  }
                }
              }
            }
          }
        `}
                render={(data) => (
                 

                        // <div className="d-block  " >
                        <Objets articles={data.allWcProducts.edges} />
                        // </div>
              
                )}
            />
        </>
    );
};

export default Shop;
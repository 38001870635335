import React from "react";
import "moment/locale/fr";
import { Container, Col } from "reactstrap";
import Carousel from "react-bootstrap/Carousel";



const objet = (objets) => {
    const data = objets.objet.node
    console.log(data)

    var titre = "";
    var price = "";
    var technique = ''
    var auteur = ''



    var affichControls = false;
    if (data.images.length <= 2) {
        affichControls = false;
    } else {
        affichControls = true;
    }


    if (typeof data.acf.si_objet_ttechnique_de_production !== "undefined") {
        technique = data.acf.si_objet_ttechnique_de_production;
    } else {
        technique = "";
    }

    if (typeof data.acf.auteur !== "undefined") {
        auteur = data.acf.auteur
    } else {
        auteur = "";
    }

    if (typeof data.name !== "undefined") {
        titre = data.name;
    } else {
        titre = "";
    }

    if (typeof data.price !== "undefined") {
        price = data.price;
    } else {
        price = "";
    }

    var affichagePremiereImage = "";
    if (typeof data.images[1] !== "undefined") {
        affichagePremiereImage = data.images[1].src;
    } else {
        affichagePremiereImage = data.images[0].src;
    }

    var affichageTroisiemeImage = "";
    if (typeof data.images[2] !== "undefined") {
        affichageTroisiemeImage = true;
    } else {
        affichageTroisiemeImage = false;
    }

    return (
        <>

            <Col sm="6" className="d-block d-md-flex px-0 pt-2 ">
                <Col sm="6" className="ml-0 pl-0 pr-0 text-left ">
                    <div className="d-block d-sm-none text-uppercase text-center textFont pb-2 pt-sm-0">
                        {titre} <br />
                    </div>

                    <div className='pr-3'>
                    <Carousel
                        controls={affichControls}
                        justify-self="end"
                        align-self="left"
                        indicators={false}
                        touch={true}
                        interval={null}
                        nextLabel="next"
                        className=""
                    >
                        <Carousel.Item>
                            <Container fluid className="text-left pr-0 pl-2">
                                <img
                                    src={affichagePremiereImage}
                                    alt="couverture"
                                    className="responsive"
                                />
                            </Container>
                        </Carousel.Item>

                        {affichageTroisiemeImage && (
                            <Carousel.Item>
                                <Container
                                    fluid
                                    className="imagescarousel text-left pr-0 px-0"
                                >
                                    <img
                                        src={data.images[2].src}
                                        alt="couverture"
                                        className="responsive"
                                    />
                                </Container>
                            </Carousel.Item>
                        )}
                    </Carousel>
                    </div>
                </Col>

                <Col sm="6" className="">
                    <div className="pb-5 pb-md-0 containerObjets">
                        <div className="d-none d-sm-block text-uppercase text-center textFont">
                            {titre} <br />
                        </div>
                        <div className="">
                            <span className="textFont ">
                                <button
                                    href=""
                                    className="snipcart-add-item text-dark textFont douze px-0 bg-white douze mb-3"
                                    data-item-id={data.wordpress_id}
                                    data-item-price={price}
                                    data-item-image={data.images[0].src}
                                    data-item-url={"/livre/" + data.slug}
                                    data-item-name={titre}
                                    data-item-weight="100"
                                >
                                    <span className="douze"> {"> "} </span>
                                    <span className="under">Ajouter au panier</span>
                                </button>
                                <br />
                                {technique}
                                <br />
                                {auteur !== "" ? <> {auteur} <br /> </> : ""}
                                {price} euros
                            </span>
                        </div>
                    </div>
                </Col>
            </Col>









        </>
    );
};

export default objet;
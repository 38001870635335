import React from "react";
import "../assets/css/main.css";
// import "../fonts/fonts.css";
import Layout2 from "../components/layout2";
import Shop from "../components/shop";
import { Container } from "reactstrap";
import Seo from "../components/seo";

const IndexPage = () => (
  <Layout2>
    <Seo
      siteTitle="Editions Divergences"
      title="Objets"
      description="Objets des éditions Divergences"
      keywords={[`Objets`]}
    />

    <div className='parution-container-parent'>
      <Shop className="" />
    </div>
    <div>
      <div className="text-white text-4xl">
Divergences Objets <br/>
Divergences Objets <br/>
Divergences Objets <br/>
Divergences Objets <br/>
Divergences Objets <br/>
Divergences Objets <br/>
Divergences Objets <br/>
Divergences Objets <br/>
Divergences Objets <br/>
Divergences Objets <br/>
Divergences Objets <br/>
Divergences Objets <br/>
Divergences Objets <br/>
Divergences Objets <br/>
Divergences Objets <br/>

      </div>
    </div>
  </Layout2>
);

export default IndexPage;
